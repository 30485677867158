import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function RankListComponent(props) {

    const [payload, setPayload] = useState([]);

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if(props.rankData===null){
            return;
        }

        (async function() {
            const params = new URLSearchParams({"ranking_type": props.rankData });
            const url = `https://people-ranking.com/v1/api/getTopRanking.php?${params.toString()}`;
            const response = await axios.get(url);
            const data = response.data;
            setPayload(data);
          })();
    }, [props.rankData])

    const [label, setLabel] = useState("");

    useEffect(() => {
        if(!props.rankType){
            return;
        }

        switch(props.rankType){
            case "most_liked":
                setLabel("RankListComponent_label_most_liked");
                break;
            case "most_disliked":
                setLabel("RankListComponent_label_most_disliked");
                break;
            case "most_trendy":
                setLabel("RankListComponent_label_most_trendy");
                break;
            default:
                setLabel("RankListComponent_label_most_liked");
                break;
        }
    }, [props.rankType])

  return (
    <div className='flex flex-col w-full'>
        <div className='bg-[#0A1128] p-4 text-[#FFFFFF] text-center'>
            <h4>{t(label)}</h4>
        </div>
        <ul className="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            {payload.map((r,k) => {
                return (
                    <li onClick={() => {navigate(`/people/${r.people_name.replaceAll(" ", "-")}-${r.people_id}`);}} key={k} className="flex items-center w-full hover:bg-slate-200 cursor-pointer px-4 py-2 border-b border-gray-200 dark:border-gray-600">
                        {k+1}&nbsp;|&nbsp;{r.people_name}
                    </li>
                )
            })}
        </ul>
        <div>
            
        </div>
    </div>
  );
}

export default RankListComponent;
