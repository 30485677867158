function TripleCardComponent() {
  return (
      <div className="TripleCardComponent flex flex-col md:flex-row items-center md:items-start md:text-left">
        <div className="flex flex-col bg-[#FFFFFF] w-[300px] h-[300px] rounded-2xl overflow-hidden mt-[20px] shadow-lg">
            <div className="relative w-[300px] h-[200px] bg-cover bg-[url('https://people-ranking.com/cdn/img/1255/3.webp')]">
                <span className="absolute top-0 left-0 text-8xl">🥉</span>
            </div>
            <div className="p-2 h-[50px]">
                <h6 className="text-lg">Bill Gates</h6>
                <div className="flex flex-wrap gap-2 mt-2">
                    <div className="rounded-2xl text-sm font-semibold p-1 px-2 bg-[#F1F1F1] text-[#222222]">Entrepreneur</div>
                </div>
            </div>
        </div>
        <div className="flex flex-col bg-[#FFFFFF] w-[300px] h-[300px] rounded-2xl overflow-hidden mt-[20px] md:mt-[80px] shadow-lg ml-[0px] md:ml-[-75px]">
            <div className="relative w-[300px] h-[200px] bg-cover bg-[url('https://people-ranking.com/cdn/img/145/3.webp')]">
                <span className="absolute top-0 left-0 text-8xl">🥈</span>
            </div>
            <div className="p-2 h-[50px]">
                <h6 className="text-lg">Jason Momoa</h6>
                <div className="flex flex-wrap gap-2 mt-2">
                    <div className="rounded-2xl text-sm font-semibold p-1 px-2 bg-[#F1F1F1] text-[#222222]">Actor</div>
                </div>
            </div>
        </div>
        <div className="flex flex-col bg-[#FFFFFF] w-[300px] h-[300px] rounded-2xl overflow-hidden mt-[20px] md:mt-[160px] shadow-lg ml-[0px] md:ml-[-75px]">
            <div className="relative w-[300px] h-[200px] bg-cover bg-[url('https://people-ranking.com/cdn/img/685/3.webp')]">
                <span className="absolute top-0 left-0 text-8xl">🥇</span>
            </div>
            <div className="p-2 h-[50px]">
                <h6 className="text-lg">Celine Dion</h6>
                <div className="flex flex-wrap gap-2 mt-2">
                    <div className="rounded-2xl text-sm font-semibold p-1 px-2 bg-[#F1F1F1] text-[#222222]">Singer</div>
                </div>
            </div>
        </div>
      </div>
  );
}

export default TripleCardComponent;
