import RankListComponent from '../../components/RankListComponent/RankListComponent';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';


function CategoriePage() {
  let { id } = useParams();

  const [catName, setCatName] = useState("")
  const [peopleList, setPeopleList] = useState([]);

  useEffect(() => {
    if(!id){
      return;
    }
    
    (async function() {
      const params = new URLSearchParams({"cat_id": id });
      const url = `https://people-ranking.com/v1/api/getCategories.php?${params.toString()}`;
      const response = await axios.get(url);

      const data = response.data;
      setCatName(data.cat.cat_label_en);
      setPeopleList(data.peoples);
    })();
  }, [id])// eslint-disable-line react-hooks/exhaustive-deps

  return (
      <div className="Home">
        <Helmet>
          <meta name="description" content={"There are many celebrities in the "+catName+" category. Choose a celebrity and vote for them!"} />
          <title>Discover new celebrities in the {catName} category!</title>
        </Helmet>
        <div className="flex p-[50px] gap-4 bg-zinc-200 shadow-md">
          <div className="p-6">
            <h1 className="leading-[3rem] text-[3rem] font-black">{catName}</h1>
            <div className="flex flex-wrap gap-2 mt-4 justify-center">
              {peopleList.map((r,k) => {
                return (
                  <button onClick={() => {window.location = `/people/${r.people_name}-${r.people_id}`}} key={k}
                    style={{background: `url("https://people-ranking.com/cdn/img/${r.people_id}/1.webp")`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"}}
                    className="flex flex-col bg-[#FFFFFF] w-[300px] h-[300px] rounded-2xl overflow-hidden shadow-lg">
                    <div className="p-2 h-[50px] w-full bg-[#FFFFFF] mt-auto">
                        <h6 className="text-lg">{r.people_name}</h6>
                    </div>
                  </button>
                )
              })}
            </div>
          </div>
        </div>
        <div className='p-[0px] md:p-[50px] flex flex-col md:flex-row gap-4 w-full'>
          <RankListComponent rankType="most_liked" rankData="1"/>
          <RankListComponent rankType="most_disliked" rankData="-1"/>
          <RankListComponent rankType="most_trendy" rankData="2"/>
        </div>
      </div>
  );
}

export default CategoriePage;